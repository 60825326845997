import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/news.tsx";
export const pageTitle = "Armeria Newsletter vol. 6";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Mailchimp = makeShortcode("Mailchimp");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "armeria-newsletter-vol-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#armeria-newsletter-vol-6",
        "aria-label": "armeria newsletter vol 6 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Armeria Newsletter vol. 6`}</h1>
    <p {...{
      "className": "date"
    }}>{`8th December 2023`}</p>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#from-the-devs"
          }}>{`From the devs`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#from-the-community"
          }}>{`From the community`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#customer-highlight"
            }}>{`Customer highlight`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#we-need-your-comments"
          }}>{`We need your comments`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#good-first-issues"
          }}>{`Good first issues`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#stay-tuned"
          }}>{`Stay tuned`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#thank-you"
          }}>{`Thank you!`}</a></p>
      </li>
    </ul>
    <video {...{
      "className": "hideOnReducedMotion",
      "src": "/bf04f0ae6df78db5375887fb6f8e2f78/armeria.m4v",
      "preload": "none",
      "autoPlay": true,
      "muted": true,
      "loop": true,
      "style": {
        "width": "282px",
        "height": "112px"
      }
    }}>{`
  `}<img parentName="video" {...{
        "src": "/2cbd433cd318026a1c3017f022dbc263/armeria.gif",
        "loading": "lazy",
        "width": 282,
        "height": 112
      }}></img>
    </video>
    <h2 {...{
      "id": "from-the-devs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-devs",
        "aria-label": "from the devs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the devs`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`✨ We've released several new versions since our last newsletter with a bunch of new exciting features! Here are the highlights between `}<a parentName="li" {...{
            "href": "/release-notes/1.24.0"
          }}>{`1.24.0`}</a>{` and `}<a parentName="li" {...{
            "href": "/release-notes/1.26.4"
          }}>{`1.26.4`}</a>{`.`}
          <ul parentName="li">
            <li parentName="ul">{`WebSocket support`}</li>
            <li parentName="ul">{`Unix domain socket support`}</li>
            <li parentName="ul">{`Out-of-the-box GraalVM native image configuration`}</li>
            <li parentName="ul">{`gRPC Richer Error Model support with `}<a parentName="li" {...{
                "href": "type://GoogleGrpcExceptionHandlerFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GoogleGrpcExceptionHandlerFunction.html"
              }}>{`type://GoogleGrpcExceptionHandlerFunction`}</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "from-the-community",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-community",
        "aria-label": "from the community permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the community`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`🎙️ `}<a parentName="li" {...{
            "href": "https://www.youtube.com/watch?v=LbBGUDGXuV8"
          }}><strong parentName="a">{`The latest in Spring Boot + Armeria integration`}</strong></a>
          <ul parentName="li">
            <li parentName="ul">{`Dive into the synergistic integration of Spring Boot 3 and Armeria in this enlightening webinar by `}<a parentName="li" {...{
                "href": "https://github.com/joshlong"
              }}><strong parentName="a">{`@joshlong`}</strong></a>{` and `}<a parentName="li" {...{
                "href": "https://github.com/trustin"
              }}><strong parentName="a">{`@trustin`}</strong></a>{`. Discover the cutting-edge features and seamless compatibility that make them an unbeatable combination for modern server-side development.`}</li>
          </ul>
        </li>
        <li parentName="ul">{`⚙️ `}<a parentName="li" {...{
            "href": "https://www.youtube.com/watch?v=xEvcG_zNwiw"
          }}><strong parentName="a">{`Internal workings of an HTTP client`}</strong></a>
          <ul parentName="li">
            <li parentName="ul">{`Think you've mastered how HTTP clients work internally? Join `}<a parentName="li" {...{
                "href": "https://github.com/jrhee17"
              }}><strong parentName="a">{`@jrhee17`}</strong></a>{` at JCConf Taiwan 2023 for an eye-opening exploration into the intricate mechanics of HTTP clients.`}</li>
          </ul>
        </li>
        <li parentName="ul">{`⚡ `}<a parentName="li" {...{
            "href": "https://www.youtube.com/watch?v=g_z_Pbjd0v0"
          }}><strong parentName="a">{`gRPC supercharged with Armeria`}</strong></a>
          <ul parentName="li">
            <li parentName="ul">{`Elevate your gRPC expertise with `}<a parentName="li" {...{
                "href": "https://github.com/trustin"
              }}><strong parentName="a">{`@trustin`}</strong></a>{` as he introduces common microservice patterns and techniques, from client-side load balancing to robust automatic retries and circuit breakers. Learn how you can implement them easily using Armeria's out-of-the-box features.`}</li>
          </ul>
        </li>
        <li parentName="ul">{`❤️‍🔥 `}<strong parentName="li">{`A heartfelt thanks to Gradle, Inc`}</strong>
          <ul parentName="li">
            <li parentName="ul">{`Immense gratitude to Gradle, Inc for their donation of the `}<a parentName="li" {...{
                "href": "https://gradle.com"
              }}>{`Gradle Enterprise`}</a>{` instance. Their collaboration has been instrumental in exponentially enhancing our build performance. This generosity and partnership are truly invaluable to our community.`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <h3 {...{
      "id": "customer-highlight",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#customer-highlight",
        "aria-label": "customer highlight permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Customer highlight`}</h3>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`💲 `}<strong parentName="li">{`Square `}<a parentName="strong" {...{
              "href": "https://code.cash.app"
            }}>{`CashApp engineering`}</a></strong>{` has adopted Armeria as their microservice framework! Top 3 benefits according to the CashApp team:`}
          <ul parentName="li">
            <li parentName="ul">{`Good docs & responsive community`}</li>
            <li parentName="ul">{`Excellent Kotlin support`}</li>
            <li parentName="ul">{`Outstanding gRPC·HTTP·etc support`}</li>
          </ul>
        </li>
        <li parentName="ul">{`🙋 `}<a parentName="li" {...{
            "href": "https://github.com/line/armeria/discussions/4626"
          }}><strong parentName="a">{`Who else are using Armeria?`}</strong></a></li>
      </ul>
    </div>
    <h2 {...{
      "id": "we-need-your-comments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#we-need-your-comments",
        "aria-label": "we need your comments permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We need your comments`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`🤔 Your opinion means a lot to us. Please let us know what you think about these proposals:`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/4792"
              }}>{`#4792`}</a>{` What would be the sensible default configuration for Armeria's latency metrics?`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/5161"
              }}>{`#5161`}</a>{` What features and customizability would you expect from Armeria's rolling updates support?`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "good-first-issues",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#good-first-issues",
        "aria-label": "good first issues permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Good first issues`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`🔰 Want to contribute but not sure where to start from? Try these issues:`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/5289"
              }}>{`#5289`}</a>{` Add `}<inlineCode parentName="li">{`EventLoopSchedulerFactory`}</inlineCode></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/5219"
              }}>{`#5219`}</a>{` Provide an option to preserve %-encoding in the request path`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "stay-tuned",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#stay-tuned",
        "aria-label": "stay tuned permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stay tuned`}</h2>
    <p>{`Sign up for our newsletters to keep up-to-date with new releases and useful tips!`}</p>
    <div style={{
      "maxWidth": "320px",
      "marginTop": "0.5rem"
    }}>
  <Mailchimp mdxType="Mailchimp" />
    </div>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you!`}</h2>
    <ThankYou message={() => <p>Since our last newsletter, we received awesome contributions from the following contributors:</p>} usernames={['0lejk4', 'AngerM-DD', 'Bue-von-hon', 'Dogacel', 'KarboniteKream', 'Kyoungwoong', 'Kyurenpoto', 'Lincong', 'aki-s', 'amirhadadi', 'anuraaga', 'arinchampatidd', 'babjo', 'baezzys', 'be-hase', 'bkkanq', 'ceki', 'chrisryan10', 'danicheg', 'echo304', 'esperar', 'geun-son', 'ghkim3221', 'haneepark', 'heowc', 'himangi-db', 'hyperxpro', 'ikhoon', 'injae-kim', 'joonhaeng', 'jrhee17', 'kezhenxu94', 'kojilin', 'ks-yim', 'lishiki', 'marcingrzejszczak', 'mauhiz', 'minwoox', 'mraasvel', 'mscheong01', 'my4-dev', 'mynameisjwlee', 'nao0811ta', 'nhacvuong', 'r3mariano', 'rhodo', 'ribafish', 'richieyan', 'runningcode', 'russell-yoo', 'seonwoo960000', 'sfc-gh-skabanov', 'sh-cho', 'sindhunaydu', 'staktrace', 'syleeeee', 'ta7uw', 'thomasbruggink', 'tobias-', 'tomatophobia', 'trustin', 'vkostyukov', 'wreulicke', 'yswdqz', 'yunjoopark']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      